.topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .topBar h1 {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 24px;
  }
  
  .topBar h1 img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .topBarRight {
    display: flex;
    align-items: center;
  }
  
  .darkModeToggle {
    display: flex;
    margin-right: 10px;
  }
  
  .darkModeToggle span {
    font-size: 24px;
    cursor: pointer;
    margin-left: 5px;
  }
  
  .menuDropdown {
    position: relative;
  }
  
  .menuDropdown span {
    font-size: 24px;
    cursor: pointer;
  }
  
  .dropdownContent {
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdownContent button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .dropdownContent button:hover {
    background-color: #f1f1f1;
  }
  
  .deleteAllButton {
    color: #D95970;
  }
  
  .deleteAllButton:hover {
    background-color: #ffebee;
  }