.morningReflection {
  margin-bottom: 20px;
}

.inputField,
.textareaField {
  margin-bottom: 15px;
}

.inputField label,
.textareaField label {
  display: block;
  margin-bottom: 5px;
}

.inputField input,
.textareaField textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.textareaField textarea {
  height: 100px;
  resize: vertical;
}

.keyTasks {
  margin-top: 20px;
}

.tasksBox {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 15px;
}

.taskItem {
  margin-bottom: 10px;
}

.taskItem input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.addTaskButton {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

/* Dark mode styles */
:global(.dark) .inputField input,
:global(.dark) .textareaField textarea,
:global(.dark) .taskItem input {
  background-color: #1a1a1a;
  color: white;
  border-color: #444;
}

:global(.dark) .inputField input:focus,
:global(.dark) .textareaField textarea:focus,
:global(.dark) .taskItem input:focus {
  outline-color: #D95970;
  outline-width: 2px;
  outline-style: solid;
  border-color: #D95970;
}

:global(.dark) .tasksBox {
  background-color: #2c2c2c;
  color: white;
}

:global(.dark) .addTaskButton {
  background-color: #D95970;
}