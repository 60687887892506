.plan {
  margin-bottom: 20px;
}

.inputField {
  margin-bottom: 15px;
}

.inputField label {
  display: block;
  margin-bottom: 5px;
}

.inputField input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Dark mode styles */
:global(.dark) .inputField input {
  background-color: #1a1a1a;
  color: white;
  border-color: #444;
}

:global(.dark) .inputField input:focus {
  outline-color: #D95970;
  outline-width: 2px;
  outline-style: solid;
  border-color: #D95970;
}

:global(.dark) .inputField label {
  color: white;
}