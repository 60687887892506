.App {
  display: flex;
  justify-content: center;
  padding: 20px;
  min-height: 100vh;
  box-sizing: border-box;
}

.journalContent {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 20px;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.darkMode .journalContent {
  background-color: rgba(30, 30, 30, 0.9);
  color: white;
}

.tabButtons {
  display: flex;
  margin-bottom: 20px;
}

.tabButtons button {
  flex: 1;
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

.darkMode .tabButtons button {
  background-color: #444;
  color: white;
}

.tabButtons button.active {
  background-color: #D95970;
  color: white;
}

.inputField,
.textareaField {
  margin-bottom: 15px;
}

.inputField label,
.textareaField label {
  display: block;
  margin-bottom: 5px;
}

.inputField input,
.textareaField textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.darkMode .inputField input,
.darkMode .textareaField textarea {
  background-color: #333;
  color: white;
  border-color: #555;
}

.textareaField textarea {
  height: 100px;
  resize: vertical;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.saveButton,
.deleteButton {
  padding: 10px;
  background-color: #D95970;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.saveButton {
  flex-grow: 1;
  margin-right: 10px;
}

.deleteButton {
  width: auto;
  white-space: nowrap;
}

.deleteButton span {
  margin-left: 5px;
}

.infoIcon {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: help;
  z-index: 1000;
}

.infoIconI {
  font-size: 24px;
  color: #333;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.darkMode .infoIconI {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
}

.infoIcon:hover .infoIconI {
  background-color: #D95970;
  color: white;
}

.infoTooltip {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  padding: 15px;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  margin-top: 10px;
}

.darkMode .infoTooltip {
  background-color: rgba(40, 40, 40, 0.95);
  color: white;
}

.infoIcon:hover .infoTooltip {
  display: block;
}

.infoTooltip h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
}

.infoTooltip p {
  margin-bottom: 10px;
}

.infoTooltip ul {
  padding-left: 20px;
  margin-bottom: 10px;
}

.kofiWidgetOverlay {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999;
}

.contactIconWrapper {
  position: fixed;
  bottom: 20px;
  right: 180px;
  z-index: 9999;
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  .journalContent {
    padding: 15px;
  }

  .tabButtons {
    flex-direction: column;
  }

  .tabButtons button {
    margin-bottom: 10px;
  }

  .buttonContainer {
    flex-direction: column;
  }

  .saveButton,
  .deleteButton {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .kofiWidgetOverlay {
    position: fixed;
    bottom: 20px;
    left: -120px;
    transition: left 0.3s ease-in-out;
  }

  .kofiWidgetOverlay:hover,
  .kofiWidgetOverlay:active {
    left: 0;
  }

  .contactIconWrapper {
    position: fixed;
    bottom: 20px;
    right: -100px;
    transition: right 0.3s ease-in-out;
  }

  .contactIconWrapper:hover,
  .contactIconWrapper:active {
    right: 0;
  }

  .infoIcon {
    top: 10px;
    right: 10px;
  }

  .infoTooltip {
    width: 250px;
    font-size: 11px;
    right: -10px;
  }
  
  .infoTooltip h3 {
    font-size: 14px;
  }
}
/* Existing styles... */

/* Add these new styles at the end of the file */

.darkMode .tasksBox,
.darkMode .planProgressBox {
  background-color: #2c2c2c;
  color: white;
}

.darkMode .taskItem {
  border-color: #555;
}

.darkMode .addTaskButton {
  background-color: #D95970;
  color: white;
}

.darkMode .progressBar {
  background-color: #555;
}

.darkMode .progressBar::-webkit-slider-thumb {
  background-color: #D95970;
}

.darkMode .progressBar::-moz-range-thumb {
  background-color: #D95970;
}

.darkMode .itemProgress {
  color: white;
}

.darkMode .taskItem input[type="checkbox"] {
  filter: invert(100%) hue-rotate(180deg) brightness(1.7);
}