.dateSelector {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dateInput,
.browseEntries {
  width: 48%;
  position: relative;
}

.dateInput input,
.browseEntries select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  height: 40px;
}

.dateInput .checkmark {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}

.browseEntries select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}

/* Dark mode styles */
:global(.dark) .dateInput input,
:global(.dark) .browseEntries select {
  background-color: #1a1a1a;
  color: white;
  border-color: #444;
}

:global(.dark) .dateInput input:focus,
:global(.dark) .browseEntries select:focus {
  outline-color: #D95970;
  outline-width: 2px;
  outline-style: solid;
  border-color: #D95970;
}

:global(.dark) .browseEntries select {
  background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

:global(.dark) .dateInput .checkmark {
  color: white;
}