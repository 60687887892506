.eveningReflection {
  margin-bottom: 20px;
}

.inputField,
.textareaField {
  margin-bottom: 15px;
}

.inputField label,
.textareaField label {
  display: block;
  margin-bottom: 5px;
}

.inputField input,
.textareaField textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.textareaField textarea {
  height: 100px;
  resize: vertical;
}

.planProgressSection {
  margin-top: 20px;
  margin-bottom: 20px;
}

.planProgressBox {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.itemProgress {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.itemText {
  margin-bottom: 5px;
}

.progressContainer {
  display: flex;
  align-items: center;
}

.progressBar {
  flex-grow: 1;
  margin-right: 10px;
  width: 150px;
  height: 10px;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background: #ddd;
  border-radius: 5px;
}

.progressBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #4CAF50;
  cursor: pointer;
  border-radius: 50%;
}

.progressBar::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #4CAF50;
  cursor: pointer;
  border-radius: 50%;
}

.progressLabel {
  min-width: 40px;
  text-align: right;
  margin-right: 10px;
}

.smiley {
  font-size: 24px;
  min-width: 30px;
}

.tomorrowPlan {
  margin-top: 20px;
}

.taskReview {
  margin-top: 20px;
  margin-bottom: 20px;
}

.tasksBox {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.taskItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.taskItem span {
  flex-grow: 1;
  margin-right: 10px;
}

.taskItem input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

/* Dark mode styles */
:global(.dark) .inputField input,
:global(.dark) .textareaField textarea {
  background-color: #1a1a1a;
  color: white;
  border-color: #444;
}

:global(.dark) .inputField input:focus,
:global(.dark) .textareaField textarea:focus {
  outline-color: #D95970;
  outline-width: 2px;
  outline-style: solid;
  border-color: #D95970;
}

:global(.dark) .planProgressBox,
:global(.dark) .tasksBox {
  background-color: #2c2c2c;
  color: white;
}

:global(.dark) .progressBar {
  background-color: #555;
}

:global(.dark) .progressBar::-webkit-slider-thumb {
  background-color: #D95970;
}

:global(.dark) .progressBar::-moz-range-thumb {
  background-color: #D95970;
}

:global(.dark) .taskItem input[type="checkbox"] {
  filter: invert(100%) hue-rotate(180deg) brightness(1.7);
}