.contactIcon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #323842;
  color: white;
  padding: 0 20px;  /* Increased horizontal padding */
  height: 42px;     /* Increased height to match Ko-fi button */
  border-radius: 21px;  /* Half of the height for rounded corners */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;  /* Slightly larger font size */
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.contactIcon:hover {
  background-color: #454b58;
}

.icon {
  margin-right: 10px;  /* Slightly increased margin */
  font-size: 18px;     /* Larger icon size */
}

.text {
  margin-top: 2px;
}

.emailTooltip {
  position: absolute;
  bottom: 50px;  /* Adjusted to account for larger button */
  right: 0;
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
}

.emailTooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 15px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

@media (max-width: 768px) {
  .contactIcon {
      position: static;
      border-top-right-radius: 21px;  /* Adjusted for new size */
      border-bottom-right-radius: 21px;  /* Adjusted for new size */
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: 12px;  /* Slightly increased */
  }

  .emailTooltip {
      bottom: 50px;  /* Adjusted to account for larger button */
      right: 10px;
  }
}